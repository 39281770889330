<template>
  <div id="app">
    <lay-out v-if="$route.path != '/login'"></lay-out>
    <router-view  v-else></router-view>
  </div>
</template>

<script>
import LayOut from '@/layout/LayOut.vue'
export default {
  name: 'App',
  components: {
    LayOut
  } 
}
</script>

<style lang="scss">
// /deep/.el-loading-mask{
//   z-index: 2016 !important;
// }
</style>
