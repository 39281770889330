import Vue from 'vue'

// 时间过滤器
Vue.filter('filterTime', (val, fmt = 'yyyy-MM-dd') => {
  if (!val) {
    return val;
  }
  let date = new Date(val);
  let o = {
    "M+": date.getMonth() + 1,                 //月份   
    "d+": date.getDate(),                    //日   
    "h+": date.getHours(),                   //小时   
    "m+": date.getMinutes(),                 //分   
    "s+": date.getSeconds(),                 //秒   
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
    "S": date.getMilliseconds()             //毫秒   
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      // console.log(fmt);
      return fmt;
})

// 会员状态
Vue.filter('filterUserType', val => {
  switch (val) {
    case 0: return '普通用户';
    case 1: return '永久会员';
    case 2: return '临时会员';
  }
})
Vue.filter('filterWidgetSize', val => {
  switch (val) {
    case 'small_app_widget': return '小';
    case 'middle_app_widget': return '中';
    case 'big_app_widget': return '大';
  }
})

//管理员等级
Vue.filter('filterPower',val=>{
  switch(val){
    case '1': return '高级用户';
    case '2': return '普通用户';
  }
})

// 筛选分类父分类
// Vue.filter('filterCategoryType', (val)=>{
//   console.log(typeArr);
// })
// 筛选状态
Vue.filter('filterCategoryStatus', (val) => {
  switch (val) {
    case 0: return '编辑中';
    case 1: return '有效';
    case 2: return '无效';
  }
})


// 筛选付费类型
Vue.filter('filterLocked', (val) => {
  switch (val) {
    case 0: return '免费不看广告';
    case 1: return '道具';
    case 2: return '会员';
  }
})

// 筛选分类
Vue.filter('filterType', (val, options) => {
  let typeName = '';
  if (val) {
    options.forEach(item => {
      if (item.value == val) {
        typeName = item.label;
      }
    })
  }
  // console.log(options);
  // console.log(val);
  return typeName;
})


// 筛选bannerType
Vue.filter('filterBannerType',(val)=>{
  switch (val) {
    case '1': return '小组件';
    case 1: return '小组件';
    case '2': return '动效';
    case 2: return '动效';
    case '3': return '壁纸';
    case 3: return '壁纸';
    case '4': return '套装';
    case 4: return '套装';
    case '5': return '活动';
    case 5: return '活动';
  }
})

// 风格分类父分类
Vue.filter('filterParentType', (val) => {
  switch (val) {
    // case '1': return '小组件';
    // case 1: return '小组件';
    // case '2': return '动画';
    // case 2: return '动画';
    // case '3': return '壁纸';
    // case 3: return '壁纸';
    // case '4': return '套装';
    // case 4: return '套装';
    // case '5': return '我的互动';
    // case 5: return '我的互动';
    case 6 : return '咻咻商店';
    case 7 :return '小组件' ;
  }
})

// 壁纸类型
// Vue.filter('filterWallPaperType', (val)=>{
//   switch (val){
//     case 1: return '锁屏';
//     case 2: return '壁纸';
//   }
// })

// 筛选多分类
Vue.filter('filterCategory', (valArr) => {
  if (valArr) {
    const nameArr = [];
    valArr.forEach(item => {
      nameArr.push(item.name);
    })
    return nameArr.join(',')
  }else{
    return '';
  }

})


Vue.filter('filterMultiCategory',(val, typeOptions)=>{
  // console.log(11);
  if(val){
    let valStr = '';
    typeOptions.forEach(item=>{
      val.split(',').forEach(it=>{
        if(item.value == it){
          valStr = valStr?val + ',' + item.label : item.label;
        }
      })
    })
    return valStr;
  }else {
    return ''
  }
})


// 过滤充值类型
Vue.filter('filterChargeType', (val)=>{
  switch (val){
    case 1: return '伏特';
    case 2: return '临时会员';
    case 3: return '永久会员';
  }
})

// 过滤充值时间
Vue.filter('filterChargeTime', (val)=>{
  if(val){
    let valOne,valTwo = [];
    valOne = val.filter((item,index)=>{
      return index <= 2;
    })
    valTwo = val.filter((item,index)=>{
      return index > 2;
    })
    return valOne.join('/') + ' ' + valTwo.join(':');
  }
})

// 过滤充值状态
Vue.filter('filterChargeStatus', (val)=>{
  switch (val){
    case 1: return '等待支付';
    case 2: return '支付成功';
  }
})
//过滤上线平台
Vue.filter('filterPlatForm', (val)=>{
  switch (val){
    case 1: return '安卓';
    case 2: return 'ios';
    case 3: return '安卓、ios'
  }
})

// 过滤充值平台
Vue.filter('filterChargePlatForm', (val)=>{
  switch (val){
    case 1: return '微信';
    case 2: return '支付宝';
  }
})
Vue.filter('filterisInvisible', (val)=>{
  switch (val){
    case 0: return '否';
    case 1: return '是';
  }
})

Vue.filter('filterAllMaterialType',(val, type, options, parentType)=>{
  let widgetOptions = options;
  console.log(val,type,options,parentType);
  let aniTypeOptions = [
    { label: "充电", value: 1 },
    { label: "来电", value: 2 },
    { label: "互动", value: 3 },
  ]
  let wallPaperTypeOptions = [
    { label: "锁屏", value: 1 },
    { label: "壁纸", value: 2 },
  ]

  // console.log(val);
  // console.log(type);
  // console.log(val);
  // console.log(options);
  let returnName = '';
  if(type == '1' || (type == '4' && parentType == '1')){
    widgetOptions.forEach(item=>{
      if(val == item.value){
        returnName = item.label
      }
    })
  }else if(type == '2' || type == '5' || (type == '4' && parentType == '2')){
    aniTypeOptions.forEach(item=>{
      if(val == item.value){
        returnName = item.label
      }
    })
  }else if(type == '3' || (type == '4' && parentType == '3')){
    wallPaperTypeOptions.forEach(item=>{
      if(val == item.value){
        returnName = item.label
      }
    })
  }

  return returnName;


})

Vue.filter('filterAllType',(val, options, parentType)=>{
  let returnName = '';
  options.forEach(item=>{
    if(item.value==val){
      returnName = item.label;
    }
  })
  return returnName;


})

Vue.filter('filterTypesSort', (val)=>{
  let allSort = [];
  if(val.types && val.types.length){
    val.types.forEach(item=>{
      allSort.push(item.sort);
    })
  }
   return allSort.join(',')
})

Vue.filter('filterTypeString', (val)=>{
  if(!val){
    return
  }
    let str =''
    val.forEach((item,i)=>{
      str += item.name
      let spot = val.length-1>i?'、':''
      str+=spot
    })
   return str
})
