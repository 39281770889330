// 自定义指令
import Vue from 'vue';
import {  Message } from 'element-ui'

Vue.directive('numberLimit',{
    bind: (el, binding)=>{
        console.log(el,binding);
        el.addEventListener('change',(context)=>{
            // console.log(binding); 
            
            console.log(Number(el.children[0].value));
            if(!Number(el.children[0].value) && Number(el.children[0].value)!==0 ){
                Message({
                    message: '输入框输入不符合类型',
                    type: 'error'
                })
                el.children[0].value = 0;
                binding.value = 0;
                // el.dispatchEvent(new Event('input'))
            }
        },true)
    }
})