const api = {
      // 用户
      userApi: {
        getAllUsers: '/web/v2.0/user/list',  // 用户列表
        updateUser: '/web/v2.0/update/user', // 更新用户
        
    },
     // 分类管理
     styleCls: {
        getCategoryType: '/web/v2.0/category/types', // 获取父分类
        getCategoryList: '/web/v2.0/category/list', // 根据父分类获取子分类
        addCategory: '/web/v2.0/category/add',   // 添加分类
        updateCategory: '/web/v2.0/category/update',   // 修改分类
     },

     //官方消息号
     OfficialNews:{
        getOperationl:'/web/adminSend/list', //操作日志列表
        getOperationlAdd:'/web/adminSend/add',  //新增任务队列
        OperationlClear:'/web/adminSend/delete',  //删除任务队列
     },
 
     //咻咻商店
     paste:{
         getPasteList:'/web/v2.0/widgetStyle/list', //获取咻咻商店数据
         getPasteAdd:'/web/v2.0/subCartoon/add', //新增咻咻商店数据
         getPasteUpdate:'/web/v2.0/subCartoon/update', //修改咻咻商店
         getPasteTop:'/web/v2.0/suit/detail/top' //修改咻咻商店位置
     },

     //贴贴相框
     photoFrom:{
        getPhotoF:"/web/v2.0/widgetStyle/photoFrame/list", //咻咻相框列表
        getPhotoFromAdd:'/web/v2.0/widgetStyle/photoFrame/add', //咻咻相框列表增加
        getPhotoFromUpdate:'/web/v2.0/widgetStyle/photoFrame/update' //咻咻相框修改
     },

     //咻咻样式
     whewStyle:{
        getWhewStyleList:'/web/v2.0/widgetStyle/whewStyle/list', //咻咻样式列表
        getWhewStyleAdd:'/web/v2.0/widgetStyle/whewStyle/add',  //新增咻咻样式
        getWhewStyleUpadate:'/web/v2.0/widgetStyle/whewStyle/update',//修改咻咻样式
     },

     //壁纸管理
     wallpaper:{
        getWallpaperlist:'web/v2.0/subWallpaper/list', //壁纸列表
        getWallpaperAdd:'/web/2.0/subWallpaper/add', //壁纸添加
        getWallpaperUpdate:'/web/2.0/subWallpaper/update' //壁纸修改
     },
     //小组件
     subWidget:{
        getWidgetList:'/web/subWidget/list', //单个小组件列表
        getWidgetAdd:'/web/v2.0/widget/add', //单个小组件新增
        getWidgetUpdate:'/web/v2.0/subWidget/update', //单个小组件修改
        getWidgetTop:'/web/widget/update', //精选组件调位置

        getWidgetdetail:'/web/widget/detail',    //查看精选小组件模板内容
        getWidgetAddDetail:'/web/v2.0/widget/addList',//新增精选小组件模板内容
        getWidgetUpdateDetail:'/web/v2.0/widgetSub/update', //精选小组件模板内容修改
        getWidgetDeleteDetail:'/web/v2.0/widgetSub/delete', //精选小组件模板内容删除
        getSingleWidgetAdd:'/web/v2.0/widget/add/subWidget', //精选小组件单个内容新增
        
     },

     //精选小组件


     getSubWidgetType:'/web/v2.0/subWidget/type', //单个小组件分类获取
     // 获取小组件模板信息
     getWidgetTemplate:'/web/v2.0/widget/template/list',

     // 安卓和ios商品价格获取
     price:{
        getAndroidPrice:'/web/v2.0/goods/list',  //安卓价格
        getAndroidPriceUpdate:'/web/v2.0/goods/update', //安卓价格修改
     },



    adminLogin: '/oauth2/admin/login', // 管理员登录

    getChannel: '/web/user/channel',  // 获取渠道号
    // 首页管理
    homePageManagement: {
        getBannerList: '/web/banner/list', // 获取banner
        addBanner: '/web/banner/add', // 新增banner
        updateBanner: '/web/banner/update', // 更新banner
        getRecommendList: '/web/recommend/list', // 获取推荐列表
        addRecommend: '/web/recommend/add', // 新增推荐
        updateRecommend: '/web/recommend/update', // 修改推荐
        // getBannerDetail: '/web/banner/detail', // 获取banner详情
    },

    // 小组件
    smallComponent: {
        getSelectType: '/web/subWidget/type', // 获取小组件下拉状态
        getSubAssemblyList: '/web/widget/list', // 获取小组件组合的表格数据
        addSubAssembly: '/web/widget/add', // 新增小组件组合
        updateSubAssembly: '/web/widget/update', // 更新小组件组合

        getWidgetList: '/web/subWidget/list',  // 获取小组件列表
        addSubWidget: '/web/subWidget/add', // 新增小组件
        updateSubWidget: '/web/subWidget/update', // 更新小组件

        recommendList: '/web/recommend/list',

        detailWidget: '/web/widget/detail', // 小组件组合详情
    },

    // 动效
    animationComponent: {
        getAnimation: '/web/cartoon/list', // 获取动效组合以及动效推荐
        getAnimationList: '/web/subcartoon/list', // 获取动效列表
        addAnimation: '/web/subcartoon/add', // 保存动效
        addAnimationCombation: '/web/cartoon/add', // 新增动效组合以及动效推荐
        updateAnimationCombation: '/web/cartoon/update', // 修改动效组合以及动效推荐
        updateAnimation: '/web/subcartoon/update', // 修改动画
        detailAnimation: '/web/cartoon/detail',   // 动效组合详情

        interActiveList: '/web/mycartoon/list', // 获取我的互动列表
    },
    // 壁纸
    wallPaperComponent: {
        getWallPaperList: '/web/wallpaper/list', // 获取壁纸组合列表
        updateWallPaperList: '/web/wallpaper/update', // 更新壁纸组合
        addWallPaperList: '/web/wallpaper/add', // 新增壁纸组合
        detailWallPaper: '/web/wallpaper/detail', // 壁纸组合详情
        getSubWallPaper: '/web/subWallpaper/list', // 获取壁纸列表
        addSubWallPaper: '/web/subWallpaper/add', // 新增壁纸
        updateSubWallPaper: '/web/subWallpaper/update', // 修改壁纸
    },

    // 套装
    suitComponent: {
        getSuitList: '/web/suit/list', // 获取套装列表
        addSuit: '/web/suit/add', // 新增套装
        updateSuit: '/web/suit/update', // 修改套装
        detailSuit: '/web/suit/detail', // 套装详情
        deleteSuitMaterial: '/web/suit/delete', // 删除套装中的素材
        addDetailContent: '/web/suit/detail/add',  // 新增套装详情
    }, 

    // 订单
    orderApi: {
        chargeData: '/api/recharge/data', // 充值数据
        orderList: '/order/list', // 订单列表
        overviewOrder: '/data/overview', // 订单回溯
        exportData: '/api/export',  // 导出
    },
    //管理员列表
    adminApi:{
        adminList:'/admin/fuzzy',//管理员列表
        addAdmin:'/admin',//新增管理员
    },
    toTop: '/web/suit/detail/top',  // 组合置顶
}

export default api;