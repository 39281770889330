<template>
  <el-container>
    <el-header>
      <div class="header-left">
        <el-image :src="require('../assets/title.webp')" fit="fill"> </el-image>
        <h6>米卡</h6>
      </div>
      <div class="header-right">
        <el-dropdown trigger="click" @command="handlerAdminMenu">
          <div>
            <!-- <el-image
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
              fit="fill">
            </el-image> -->
            <i
              class="el-icon-user-solid"
              style="font-size: 25px; margin-right: 5px"
            ></i>
            <h6>{{ $store.state.adminName }}</h6>
          </div>

          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="versionData">{{
              $store.state.version == "1" ? "Android" : "IOS"
            }}</el-dropdown-item> -->
            <el-dropdown-item command="exit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside :width="isFold ? '64px' : '200px'" ref="sideRef">
        <side-menu :isFold="isFold"></side-menu>
        <div class="fold-box" :style="isFold ? 'width: 64px' : 'width: 200px'">
          <i
            class="el-icon-s-unfold"
            v-if="isFold"
            @click="isFold = !isFold"
          ></i>
          <i class="el-icon-s-fold" v-else @click="isFold = !isFold"></i>
        </div>
      </el-aside>
      <el-main
        ref="mainRef"
        :style="isFold ? 'margin-left: 64px;' : 'margin-left: 200px;'"
      >
        <transition name="slide-fade">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideMenu from "@/layout/SideMenu.vue";
export default {
  components: {
    SideMenu,
  },
  data() {
    return {
      isFold: false,
    };
  },
  mounted() {
    console.log(document.documentElement.clientHeight);
    this.$nextTick(() => {
      console.log(this.$refs["sideRef"]);
      this.$refs["sideRef"].$el.style.height =
        document.documentElement.clientHeight - 60 + "px";
      this.$refs["mainRef"].$el.style.height =
        document.documentElement.clientHeight - 60 + "px";
    });
  },
  methods: {
    handlerAdminMenu(command) {
      if (command == "exit") {
        this.exitSystem();
      }
      // else if (command == "versionData") {
      //   const version = this.$store.state.version;
      //   this.$confirm(
      //     "你确定要切换到" +( version == "1" ? "Android" : "IOS") + "后台吗?",
      //     "提示",
      //     {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     }
      //   ).then(() => {
      //     if (version == "1") {

      //       this.$store.commit("changeVersionData", "2");

      //       this.$message({
      //         message: "已切换到Andriod",
      //         type: "success",
      //       });
      //     } else if (version == "2") {
      //       this.$store.commit("changeVersionData", "1");

      //       this.$message({
      //         message: "已切换到IOS",
      //         type: "success",
      //       });
      //     }
      //   }).catch(()=>{
      //     this.$message({
      //       message: '已取消切换',
      //       type: 'warning'
      //     })
      //   })
      // }
    },

    // 退出系统
    exitSystem() {
      this.$confirm("你确认退出系统吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(() => {
          window.sessionStorage.removeItem("user");
          this.$store.commit("changeIsLogin", false);
          this.$message({
            message: "你已退出登录!",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "已取消退出!",
            type: "warning",
          });
        });
    },
  },
};
</script>

// 全局样式
<style lang="scss">
.fold-box {
  width: 200px;
  height: 50px;
  background: #373d41;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  i {
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
}
.el-menu--vertical {
  background: none;
  .el-menu {
    background-color: #222;
    margin-left: 0;
  }
}

// 全局输入框样式
.search-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  .el-input,
  .el-select {
    width: 10%;
    min-width: 150px;
    margin-right: 10px;
  }
  .el-select {
    .el-input {
      width: 100%;
    }
  }
  .el-date-editor {
    width: 20%;
    min-width: 250px;
    margin-right: 20px;
  }
  .el-date-editor--date {
    width: 15%;
    min-width: 200px;
    margin-right: 20px;
  }
  > div,
  > button {
    margin-bottom: 20px;
  }
}

// 表格
.table-box {
  padding: 0 20px;
  // height: 83%;
  overflow: auto;
}
.el-table__body-wrapper {
  overflow: auto;
}

.el-pagination {
  padding: 10px 20px;
  box-sizing: border-box;
}

// 页面进入和滑出的动画
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

// 只控制左侧导航以及头部
<style lang="scss" scoped>
// 头部
.el-header {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: #373d41;
  color: #333;
  // text-align: center;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0;
}
.el-header .header-left,
.el-header .header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  h6 {
    color: white;
    font-size: 20px;
    line-height: 0;
  }
}
.el-header .header-right {
  .el-image {
    width: 30px;
    height: 30px;
  }
  h6 {
    font-size: 12px;
  }
}

.el-dropdown-selfdefine {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.el-dropdown-menu {
  width: 100px;
  background: #373d41;
  border: none;
  border-radius: 0;
  left: 1310px;
}
.el-dropdown-menu__item {
  color: white;
  text-align: center;
}
.el-dropdown-menu__item:hover {
  background: #373d41;
}
.el-popper {
  margin: 0;
}
.el-popper /deep/.popper__arrow,
.el-popper /deep/.popper__arrow::after {
  content: "";
  border: none !important;
  border-bottom: none;
}
// 左侧导航
.el-aside {
  background-color: rgb(51, 55, 68);
  position: fixed;
  z-index: 2;
  margin-top: 60px;
}

.el-aside::-webkit-scrollbar {
  width: 5px;
}
.el-aside::-webkit-scrollbar-track {
  background-color: #000;
}

.el-aside::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 5px;
}

.el-aside::-webkit-scrollbar-thumb:hover {
  background-color: #4a4a4a;
  border-radius: 5px;
}

.el-aside::-webkit-scrollbar-thumb:active {
  background-color: #4a4a4a;
  border-radius: 5px;
}

// 右侧内容
.el-main {
  background-color: #e9eef3;
  margin-top: 60px;
}
.el-main > div {
  background: #fff;
  height: 100%;
  overflow: auto;
  // position: relative;
  // overflow: auto;
}
</style>