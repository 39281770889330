// 全局函数
import { Message } from 'element-ui'

const commFun = {
    responseAfter(res, _this, dialogTitle){
        console.log(res);
        if(res.code == 200){
            Message({
                message: res.message,
                type: 'success'
            })
            _this.getTableData();
            if(dialogTitle){
                _this[dialogTitle].visible = false;
            }else{
                _this.dialogInfo.visible = false;
            }
            if(dialogTitle){
                _this.upDialogInfo.visible = false;
            }else{
                _this.upDialogInfo.visible = false;
            }
            
        }else{
            Message({
                message: res.message,
                type: 'error'
            })
        }
    }
}

export default commFun;