// axios 封装请求拦截器， 响应拦截器
import axios from 'axios'
import { Notification, MessageBox, Message, Loading} from 'element-ui'

import store from '../store/index'

let loading = '';
let showLoading = true;
// let baseURL = 'http://192.168.1.192:8071' 
// let baseURL = "http://192.168.1.21:8071"
// let baseURL = 'http://192.168.1.21:8071'  // 谭
// let baseURL = 'http://47.108.179.208:8071' // 测试
let baseURL = 'http://139.186.129.24:8070' // 正式
// let baseURL = 'http://hengqifeng:8071'

 
// 创建axios实例
const service = axios.create({
  baseURL, // api 的 base_url
  // baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 1000*60*60*3 // 请求超时时间
})
 
// request拦截器
service.interceptors.request.use(
  config => {
    let token = window.sessionStorage.getItem('user');
    if(token){
      config.headers['token'] = token;
      // config.headers['content-type'] = 'application/x-www-form-urlencoded'
    }
    
    // config.headers['Content-Type'] = 'application/json'
    // loading状态
    showLoading = config.showLoading?config.showLoading:true;
    if(showLoading){
      loading = Loading.service({ 
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
     });
    }
    
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)
 
// response 拦截器
service.interceptors.response.use(
  response => {
    // 关闭loading状态
    if(showLoading){
      loading.close();
    }
    const code = response.status;
    if (code < 200 || code > 300) {
      Notification.error({
        title: '请求错误,错误码：' + code
      })
      return Promise.reject('error')
    }else {
      if(response.data.code == 9999){
        Message({
          message: '用户登录信息过期，请重新登录!',
          type: 'warning'
        })
        store.commit('changeIsLogin',false);
        return ;
      }
      return response.data
    }
  },
  error => {
    // 关闭loading状态
    if(showLoading){
      loading.close();
    }
    
    let code = 0;
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 2500
        })
        return Promise.reject(error)
      }
      if (error.toString().indexOf('Error: Network Error') !== -1) {
        Notification.error({
          title: '网络请求错误',
          duration: 2500
        })
        return Promise.reject(error)
      }
    }
    if (code === 401 || code === 9999) {
      MessageBox.confirm(
        '登录状态已过期，您可以继续留在该页面，或者重新登录',
        '系统提示',
        {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(()=>{
        store.commit('changeIsLogin',false);
      })
    } else if (code === 403) {
        store.commit('changeIsLogin',false);
    } else {
      console.log(error);
      const errorMsg = error.response.data.message
      if (errorMsg !== undefined) {
        Notification.error({
          title: '请求错误,错误码：' + code,
          duration: 2500
        })
      }
    }
    return Promise.reject(error)
  }
)
export default service