import Vue from 'vue'
import Router from 'vue-router'

import store from '../store/index'

Vue.use(Router)

const routes = [
    // 登录
    {
        path: '/login',
        component: ()=> import('@/views/login/Login.vue')
    },
    // 用户管理
    {
        path: '/userManagement',
        component: ()=> import('@/views/user/UserManagement.vue')
    },
    // 路由重定向
    {
        path:'/',
        redirect:"userManagement"
    },
    // banner管理
    // {
    //     path: '/bannerManagement',
    //     component: ()=> import('@/views/mainPage/BannerManagement.vue')
    // },
    // // 套装推荐
    // {
    //     path: '/suitRecommend',
    //     component: ()=> import('@/views/mainPage/SuitRecommend.vue')
    // },
    // // 小组件/动效组合推荐
    // {
    //     path: '/combationRecommend',
    //     component: ()=> import('@/views/mainPage/CombationRecommend.vue')
    // },
    // // 壁纸推荐
    // {
    //     path: '/wallPaperRecommend',
    //     component: ()=> import('@/views/mainPage/WallPaperRecommend.vue')
    // },
    // 风格分类
    // 咻咻商店
    {
        path:"/paste", 
        component:()=>import('@/views/paste/PasteCom.vue')
    },
    //分类管理
    {
        path: '/styleCls',
        component: ()=> import('@/views/classifiedManagement/StyleManagement.vue')
    },
    // 贴贴相框
    {
        path: '/photoFrame',
        component: ()=> import('@/views/photoFrame/photoFrame.vue')
    },
    //咻咻样式
    {
        path:'/whewStyle',
        component: ()=> import('@/views/whewStyle/index.vue')
    },

    //壁纸管理
    {
        path:'/wallpaper',
        component: ()=> import('@/views/wallpaper/index.vue')
    },
    //小组件精选
    {
        path:'/selectedCom',
        component: ()=> import('@/views/widget/selectedCom.vue')
    },
    //单个小组件
    {
        path:'/singleCom',
        component: ()=> import('@/views/widget/singleCom.vue')
    },
    // // 套装
    // {
    //     path: '/suit',
    //     component: ()=> import('@/views/suit/SuitComponent.vue')
    // },
    // // 小组件
    // {
    //     path: '/recommendComponent',
    //     component: ()=> import('@/views/smallComponent/RecommendComponent.vue')
    // },
    // // 小组件组合
    // {
    //     path: '/subAssembly',
    //     component: ()=> import('@/views/smallComponent/SubAssembly.vue')
    // },
    // // 动效
    // {
    //     path: '/smallComponent',
    //     component: ()=> import('@/views/smallComponent/SmallComponent.vue')
    // },
    // {
    //     path: '/animationRecommend',
    //     component: ()=> import('@/views/animationComponent/RecommendComponent.vue')
    // },
    // {
    //     path: '/animationPage',
    //     component: ()=> import('@/views/animationComponent/AnimationPage.vue')
    // },
    // // 动效组合
    // {
    //     path: '/animationCombation',
    //     component: ()=> import('@/views/animationComponent/AnimationCombation.vue')
    // },
    // // 我的互动
    // {
    //     path: '/myAniRecommend',
    //     component: ()=> import('@/views/animationComponent/MyAniRecommend.vue')
    // },
    // // 壁纸
    // {
    //     path: '/wallPaper',
    //     component: ()=> import('@/views/wallPaperComponent/WallPaper.vue')
    // },
    // // 壁纸组合
    // {
    //     path: '/wallPaperCombation',
    //     component: ()=> import('@/views/wallPaperComponent/WallPaperCombation.vue')
    // },
    // // 充值统计
        {
            path: '/chargeStatistic',
            component: ()=> import('@/views/order/ChargeStatistic.vue')
        },
        // 订单列表
        {
            path: '/orderList',
            component: ()=> import('@/views/order/OrderList.vue')
        },
        // //  新增用户充值追踪
        // {
        //     path: '/chargeTrack',
        //     component: ()=> import('@/views/order/ChargeTrack.vue')
        // },
    //  管理员列表
    {
        path: '/adminList',
        component: ()=> import('@/views/adminList/AdminList.vue')
    },
    {       //ios价格管理
        path: '/iosprice',
        component: ()=> import('@/views/price/iosPrice')
    },
    { 
        path: '/androidpirce',
        component: ()=> import('@/views/price/androidPrice')
    },
    { 
        path: '/operation',
        component: ()=> import('@/views/OfficialNews/OperationlLog')
    },
    { 
        path: '/push',
        component: ()=> import('@/views/OfficialNews/NewPush')
    },
]




const router = new Router({
    routes
});

// 路由守卫(前置)
router.beforeEach((to,from,next)=>{
    // console.log(to);
    const user = window.sessionStorage.getItem('user');
    if(user){
        store.commit('changeIsLogin', true);
        store.commit('changePower', window.sessionStorage.getItem('power'));
    }
    // console.log(user);
    // debugger
    if(!user && !store.state.isLogin && to.path != '/login'){ // 一定要加 条件 to.path != '/login' 不然会进入死循环，报错
        next({
            path: '/login',
        });
    }else{
        next();
    }
})

export default router;