<template>
  <el-menu
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    :collapse="isFold"
    :router="true"
    :collapse-transition="false"
    :popper-append-to-body="true"
  >
    <sub-menu
      v-for="(item, index) in menuList"
      :menuItem="item"
      :key="index"
      :isFold="isFold"
    ></sub-menu>
  </el-menu>
</template>

<script>
import SubMenu from "./component/SubMenu.vue";
export default {
  components: {
    SubMenu,
  },
  props: {
    isFold: Boolean,
  },
  data() {
    return {
      menuList: [
        { title: "用户管理", index: "/userManagement", icon: "el-icon-user" },
        {
          title: "分类管理",
          index: "/styleCls",
          icon: "el-icon-document-copy",
          
        },
         {
          title: "咻咻商店",
          index: "/paste",
          icon: "el-icon-s-shop",
        },
        {
          title: "小组件",
          index: "5",
          icon: "el-icon-connection",
          children: [
            // { title: "推荐", index: "/recommendComponent", icon: "" },
            { title: "精选", index: "/selectedCom", icon: "" },
            { title: "单个小组件", index: "/singleCom", icon: "" },
          ],
        },
        {
          title: "贴贴相框",
          index: "/photoframe",
          icon: "el-icon-files",
          // children: [{ title: "套装", index: "/suit", icon: "" }],
        },
        {
          title: "咻咻样式",
          index: "/whewStyle",
          icon: "el-icon-set-up",
          // children: [
          //   // { title: "推荐", index: "/animationRecommend", icon: "" },
          //   { title: "动效", index: "/animationPage", icon: "" },
          //   { title: "动效组合", index: "/animationCombation", icon: "" },
          //   { title: "我的互动", index: "/myAniRecommend", icon: "" },
          // ],
        },
        {
          title: "壁纸",
          index: "/wallPaper",
          icon: "el-icon-tickets",
          children: [
            // { title: "推荐", index: "/animationRecommend", icon: "" },
            // { title: "壁纸", index: "/wallPaper", icon: "" },
            // { title: "壁纸组合", index: "/wallPaperCombation", icon: "" },
          ],
        },
        {
          title: "订单管理",
          index: "8",
          icon: "el-icon-s-order",
          children: [
            {title: "充值统计", index: "/chargeStatistic", icon: ""},
            { title: "订单列表", index: "/orderList", icon: "" },
            // {title: "新增用户充值追踪", index: "/chargeTrack", icon: ""}
          ],
        },
        {
          title: "官方号消息",
          index: "/operation",
          icon: "el-icon-s-order",
          // children: [
          //   {title: "操作日志", index: "/operation", icon: ""},
          //   { title: "新建推送", index: "/push", icon: "" },
          //   // {title: "新增用户充值追踪", index: "/chargeTrack", icon: ""}
          // ],
        },
        { title: "管理员列表",
        index: "/adminList", 
        icon: "el-icon-s-management",
        },
        { title: "价格管理",
        index: "10", 
        icon: "el-icon-edit",
        children: [
          {title: "安卓价格管理", index: "/androidpirce", icon: ""},
          { title: "ios价格管理", index: "/iosprice", icon: "" },
        ]
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-menu {
  width: 100%;
  background-color: rgb(51, 55, 68) !important;
  box-sizing: border-box;
  border: none;
  padding-bottom: 35px;
}
/deep/ul.el-menu {
  background-color: #222;
}
// /deep/.el-menu-vertical-demo:not(.el-menu--collapse) {
//     width: 200px;
//     min-height: 400px;
// }
.el-menu--collapse > div > .el-submenu > .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
.el-menu--collapse
  > div
  > .el-submenu
  > .el-submenu__title
  .el-submenu__icon-arrow {
  display: none;
}
</style>