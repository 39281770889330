// 状态管理
import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router/index'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
       isLogin: false, 
       adminName: '',
       power: '-1',
        templateSelection: {
            index:-1, 
            row:{}
        },
        selectList:[],
    },
    mutations: {  
        handleSelectList(state,val){
            state.selectList = [...val]
        },

        // 更改登录状态 
        changeIsLogin(state,status){
            state.isLogin = status;
            if(!status){
                router.push({ path: '/login'})
                window.sessionStorage.removeItem('adminName');
            }else{
                state.adminName = window.sessionStorage.getItem('adminName');
            }
        },
        handleClear(state){
            state.selectList = [];
            state.templateSelection.row = {};
            state.templateSelection.index = -1
        },
        // 保存用户名
        getUserName(state, name){
            window.sessionStorage.setItem('adminName', name);
            // state.adminName = name;
        },
        // 切换设备
        changeVersionData(state, val){
            window.sessionStorage.setItem('version', val);
        
            router.push({ path: '/'});
            router.go(0)
        },
        // 改变power
        changePower(state, val){
            state.power = val;
        },
        handleTemplateSelection(state,{row,index}){
            console.log(1);
            state.templateSelection.index = index 
            state.templateSelection.row = row
        }
    },
    actions: {

    }
})

export default store;