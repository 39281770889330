<template>
  <div>
    <el-submenu
      :index="menuItem.index"
      v-if="menuItem.children && menuItem.children.length"
      :class="isFold ? 'icon-arrow' : ''"
    >
      <template slot="title">
        <i :class="menuItem.icon"></i>
        <span slot="title">{{ menuItem.title }}</span>
      </template>
      <sub-menu
        v-for="(item, index) in menuItem.children"
        :menuItem="item"
        :key="index"
      ></sub-menu>
    </el-submenu>
    <template v-else>
      <el-menu-item :index="menuItem.index" :route="menuItem.index" v-if="!(menuItem.show?menuItem.show:false)">
        <i :class="menuItem.icon"></i>
        <span slot="title">{{ menuItem.title }}</span>
      </el-menu-item>
    </template>
    
  </div>
</template>

<script>
export default {
  name: "SubMenu",
  props: {
    menuItem: Object,
    isFold: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.el-submenu:hover,
/deep/.el-submenu__title:hover,
/deep/li ul .el-submenu__title:hover,
/deep/.el-menu-item:hover,
/deep/.el-menu-item.is-active:hover,
/deep/.el-menu-item.is-active:focus {
  background: none;
}
.el-menu-item {
  color: white;
}
/deep/.el-submenu__title {
  color: white;
}
.el-menu-item.is-active {
  color: rgb(255, 208, 75);
}
.el-menu--collapse > div > .el-submenu > .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
.icon-arrow {
  /deep/.el-submenu__icon-arrow {
    display: none;
  }
}
</style>