import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
// import './utils/require.js';
// import './utils/rquire'
import router from './router/index'
import store from './store/index'


import './utils/directive'
import './utils/filter'

import service from './utils/request'
import api from './utils/api'
import commFun from './utils/commFun'


// import Blob from './vendor/Blob'
// import Export2Excel from './vendor/Export2Excel.js'

Vue.use(ElementUI);
Vue.use(commFun);

Vue.config.productionTip = false

// 全局
Vue.prototype.$axios = service;
Vue.prototype.$api = api;
Vue.prototype.commFun = commFun;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
